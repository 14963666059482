body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	/* background-color: #f7f7f7 !important; */
	background-color: white !important;
	font-size: 15px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h6 {
	color: #0f4354;
}
h5 {
	color: #0f4354;
}
h4 {
	color: #0f4354;
}
h3 {
	color: #0f4354;
}
h2 {
	color: #0f4354;
}
h1 {
	color: #0f4354;
}

a {
	color: #0f4354;
}

a:hover {
	color: #0f4354;
}

.numberCircle {
	width: 50px;
	line-height: 50px;
	border-radius: 50%;
	text-align: center;
	font-size: 32px;
	border: 3px solid #0f4354;
}

.numberCircleSmall {
	width: 35px;
	line-height: 35px;
	border-radius: 50%;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	border: 3px solid #0f4354;
}
